import classnames from 'classnames';
import { Entry } from 'contentful';
import isEmpty from 'lodash/isEmpty';
import { Fragment } from 'react';

import Carousel from 'components/Carousel/Carousel';
import ContentfulImage from 'components/contentfulImage/ContentfulImage';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { CarouselImageSizes } from 'lib/carouselImageSizes';
import { TastemakerCarouselModuleEntry, TastemakerModel } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import { TastemakersLink } from 'lib/links';
import Logger from 'lib/utils/Logger';

import styles from './TastemakerCarouselModule.module.scss';

import CarouselModuleHeader from '../CarouselModuleHeader/CarouselModuleHeader';
import { ContentfulModuleProps } from '../sharedTypes';
import { buildAllImageAssets } from '../utils';

export type TastemakerCarouselModuleProps = ContentfulModuleProps<
  TastemakerCarouselModuleEntry
>;

const renderTastemakerCard = (
  tastemaker: Entry<TastemakerModel>,
  index: number
) => {
  const { handle, profileImage, shortDescription, slug } = tastemaker.fields;
  try {
    const imageUrl = getUrlFromAsset(profileImage);

    return (
      <div
        className={styles.card}
        data-card-position={index}
        key={tastemaker.sys.id}
      >
        <TastemakersLink slug={slug}>
          <a aria-label={shortDescription} href="placeholder">
            <ContentfulImage
              alt={shortDescription}
              assets={buildAllImageAssets(profileImage)}
              className={styles.image}
              largeSrc={{ url: imageUrl, width: CarouselImageSizes.large }}
              mediumSrc={{
                url: imageUrl,
                width: CarouselImageSizes.medium,
              }}
              smallSrc={{ url: imageUrl, width: CarouselImageSizes.small }}
              src={{ url: imageUrl, width: CarouselImageSizes.extraSmall }}
              xLargeSrc={{
                url: imageUrl,
                width: CarouselImageSizes.extraLarge,
              }}
            />
          </a>
        </TastemakersLink>
        <div className={styles.handle}>{handle}</div>
        <div className={styles.shortDescription}>{shortDescription}</div>
      </div>
    );
  } catch (error) {
    Logger.error(
      `Unable to render tastemaker card in TastemakerCarouselModule, tastemaker: ${JSON.stringify(
        tastemaker
      )}`,
      error
    );
  }
};

export const renderTastemakerCards = (
  tastemakers: TastemakerCarouselModuleEntry['fields']['tastemakers']
) => {
  if (isEmpty(tastemakers)) {
    return null;
  }

  return tastemakers
    .map((tastemaker, index) => {
      return renderTastemakerCard(tastemaker, index);
    })
    .filter((card: JSX.Element | undefined): card is JSX.Element => !!card);
};

const TastemakerCarouselModule = (props: TastemakerCarouselModuleProps) => {
  const { headerData, link, tastemakers } = props.entry.fields;

  return (
    <div
      className={classnames(styles.root, props.className)}
      {...props.dataAttributes}
    >
      {headerData && <CarouselModuleHeader headerEntry={headerData} />}
      <Carousel
        cardsContainerClassName={styles.cardsContainer}
        classNameForCenteringArrows={styles.image}
      >
        <Fragment>{renderTastemakerCards(tastemakers)}</Fragment>
      </Carousel>
      {link && (
        <LocalizableLink className={styles.link} {...link.fields}>
          {link.fields.displayText} &#8250;
        </LocalizableLink>
      )}
    </div>
  );
};

export default TastemakerCarouselModule;
