import classnames from 'classnames';

import GridLayoutItem from './components/GridLayoutItem/GridLayoutItem';
import { Column, Container, Row } from 'components/Grid';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { GridLayoutModuleEntry } from 'lib/contentful';
import Logger from 'lib/utils/Logger';

import styles from './GridLayoutModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type GridLayoutModuleProps = ContentfulModuleProps<GridLayoutModuleEntry>;

const GridLayoutModule = (props: GridLayoutModuleProps) => {
  try {
    const { largeItem, link, smallItems } = props.entry.fields;
    const [smallItemOne, smallItemTwo] = smallItems;

    return (
      <div
        className={classnames(styles.root, props.className)}
        {...props.dataAttributes}
      >
        <Container className={styles.gridContainer} fluid>
          <Row>
            <Column md={8} xs={12}>
              <GridLayoutItem entry={largeItem} />
            </Column>

            <Column md={4} xs={12}>
              <Row className={styles.smallItemRow}>
                <Column md={12} sm={6} xs={12}>
                  <GridLayoutItem entry={smallItemOne} isSmall />
                </Column>

                <Column md={12} sm={6} style={{ marginTop: 'auto' }} xs={12}>
                  <GridLayoutItem entry={smallItemTwo} isSmall />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>

        {link && (
          <LocalizableLink {...link.fields} className={styles.link}>
            {link.fields.displayText} &#8250;
          </LocalizableLink>
        )}
      </div>
    );
  } catch (error) {
    Logger.error('GridLayoutModule', error);
    return null;
  }
};

export default GridLayoutModule;
