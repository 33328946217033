import classnames from 'classnames';

import TallHeroBannerModuleButton from './components/TallHeroBannerModuleButton/TallHeroBannerModuleButton';
import ContentfulImage from 'components/contentfulImage/ContentfulImage';

import {
  TallHeroBannerModuleEntry,
  TallHeroBannerModuleHorizontalAlignment,
  TallHeroBannerModuleVerticalAlignment,
  TextColor,
} from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

import styles from './TallHeroBannerModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';
import { buildImageSrcSet } from '../utils';

const getHorizontalAlignmentClassName = (
  horizontalAlignment: TallHeroBannerModuleHorizontalAlignment
) => {
  switch (horizontalAlignment) {
    case TallHeroBannerModuleHorizontalAlignment.Center:
      return styles.horizontalAlignCenter;

    case TallHeroBannerModuleHorizontalAlignment.Left:
      return styles.horizontalAlignLeft;

    case TallHeroBannerModuleHorizontalAlignment.Right:
      return styles.horizontalAlignRight;

    default:
      return '';
  }
};

type TallHeroBannerModuleProps = ContentfulModuleProps<
  TallHeroBannerModuleEntry
>;

const TallHeroBannerModule = (props: TallHeroBannerModuleProps) => {
  try {
    const { className } = props;
    const {
      buttons,
      header,
      horizontalAlignment = TallHeroBannerModuleHorizontalAlignment.Center,
      image,
      imageQuality,
      mobileImage,
      mobileImageQuality,
      subHeader,
      textColor = TextColor.Light,
      verticalAlignment = TallHeroBannerModuleVerticalAlignment.Center,
    } = props.entry.fields;

    const imageUrl = getUrlFromAsset(image);
    const mobileImageUrl = mobileImage
      ? getUrlFromAsset(mobileImage)
      : imageUrl;

    const imageSrcSetProps = {
      imageQuality,
      imageUrl,
      mobileImageQuality,
      mobileImageUrl,
    };
    const imageSrcSet = buildImageSrcSet(imageSrcSetProps);

    const textColorClassName =
      textColor === TextColor.Light ? styles.lightText : styles.darkText;

    const verticalAlignClassName =
      verticalAlignment === TallHeroBannerModuleVerticalAlignment.Bottom
        ? styles.verticalAlignBottom
        : styles.verticalAlignCenter;

    const horizontalAlignClassName = getHorizontalAlignmentClassName(
      horizontalAlignment
    );

    return (
      <div className={classnames(styles.root, className, textColorClassName)}>
        <ContentfulImage
          className={styles.image}
          imageContainerClassName={styles.imageContainer}
          {...imageSrcSet}
        />
        <div
          className={classnames(
            styles.contentContainer,
            horizontalAlignClassName,
            verticalAlignClassName
          )}
        >
          <div className={styles.contentInner}>
            <div className={styles.header}>{header}</div>

            {subHeader && <div className={styles.subHeader}>{subHeader}</div>}

            <div className={styles.buttons}>
              {buttons.map(button => (
                <TallHeroBannerModuleButton
                  entry={button}
                  key={button.sys.id}
                  textColor={textColor}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } catch (error) {
    Logger.warn('Error rendering TallHeroBannerModule', error);
    return null;
  }
};

export default TallHeroBannerModule;
