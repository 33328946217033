import classnames from 'classnames';
import { Asset } from 'contentful';

import CtaButton from 'components/Buttons/CtaButton';
import Image from 'components/Image/Image';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { Breakpoints } from 'lib/breakpoints';
import {
  DoubleImageBannerModuleEntry,
  LocalizableLinkEntry,
} from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

import styles from './DoubleImageBannerModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

const getImageSrcs = (imageUrl: string, mobileImage?: string) => {
  const src = {
    url: mobileImage || imageUrl,
    width: Breakpoints.widthSmall,
  };
  const smallSrc = { url: imageUrl, width: Breakpoints.widthMedium / 2 };
  const mediumSrc = { url: imageUrl, width: Breakpoints.widthExtraLarge / 2 };

  return { mediumSrc, smallSrc, src };
};

type DoubleImageBannerModuleProps = ContentfulModuleProps<
  DoubleImageBannerModuleEntry
>;

type DoubleImageBannerModuleSection = {
  buttonText: string;
  image: Asset;
  isLight: boolean;
  link: LocalizableLinkEntry;
  mobileImage?: Asset;
  title: string;
};

const DoubleImageBannerModule = (props: DoubleImageBannerModuleProps) => {
  try {
    const [sectionOne, sectionTwo] = buildSections(props);

    return (
      <div
        className={classnames(styles.sectionsContainer, props.className)}
        {...props.dataAttributes}
      >
        {[sectionOne, sectionTwo].map((section, index) => {
          const {
            buttonText,
            image,
            isLight,
            link,
            mobileImage,
            title,
          } = section;
          const imageUrl = getUrlFromAsset(image);
          const mobileImageUrl = mobileImage && getUrlFromAsset(mobileImage);
          const imageSrcs = getImageSrcs(imageUrl, mobileImageUrl);

          return (
            <LocalizableLink
              {...link.fields}
              className={styles.section}
              key={index}
            >
              <Image alt={title} className={styles.image} {...imageSrcs} />
              <div className={styles.content}>
                {/* Mobile Only */}
                <div {...link.fields} className={styles.mobileOnlyContent}>
                  <h3
                    className={classnames(styles.mobileOnlyText, {
                      [styles.whiteText]: isLight,
                    })}
                  >
                    {buttonText}
                  </h3>
                </div>

                {/* Non-Mobile Only */}
                <div className={styles.nonMobileContent}>
                  <h3
                    className={classnames(styles.nonMobileTitle, {
                      [styles.whiteTitle]: isLight,
                    })}
                  >
                    {title}
                  </h3>
                  <div
                    {...link.fields}
                    className={styles.nonMobileButtonContainer}
                  >
                    <CtaButton
                      className={styles.nonMobileButton}
                      isTertiaryButton={isLight}
                    >
                      {buttonText}
                    </CtaButton>
                  </div>
                </div>
              </div>
            </LocalizableLink>
          );
        })}
      </div>
    );
  } catch (error) {
    Logger.warn(
      `Unable to render DoubleImageBannerModule - props: ${JSON.stringify(
        props
      )}`,
      error
    );
    return null;
  }
};

const buildSections = (
  props: DoubleImageBannerModuleProps
): DoubleImageBannerModuleSection[] => {
  const { fields } = props.entry;

  const section1: DoubleImageBannerModuleSection = {
    buttonText: fields.section1ButtonText,
    image: fields.section1Image,
    isLight: fields.section1IsLight,
    link: fields.section1Link,
    mobileImage: fields.section1MobileImage,
    title: fields.section1Title,
  };

  const section2: DoubleImageBannerModuleSection = {
    buttonText: fields.section2ButtonText,
    image: fields.section2Image,
    isLight: fields.section2IsLight,
    link: fields.section2Link,
    mobileImage: fields.section2MobileImage,
    title: fields.section2Title,
  };

  return [section1, section2];
};

export default DoubleImageBannerModule;
