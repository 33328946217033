import classnames from 'classnames';

import SecondaryButton from 'components/Buttons/SecondaryButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import {
  TallHeroBannerModuleButtonEntry,
  TallHeroBannerModuleButtonType,
  TextColor,
} from 'lib/contentful';
import Logger from 'lib/utils/Logger';

import styles from './TallHeroBannerModuleButton.module.scss';

type TallHeroBannerModuleButtonProps = {
  entry: TallHeroBannerModuleButtonEntry;
  textColor: TextColor;
};

const TallHeroBannerModuleButton = (props: TallHeroBannerModuleButtonProps) => {
  try {
    const { entry, textColor } = props;

    const {
      buttonType = TallHeroBannerModuleButtonType.Primary,
      link,
    } = entry.fields;

    const textColorClassName =
      textColor === TextColor.Light ? styles.lightText : styles.darkText;

    return (
      <LocalizableLink className={styles.root} {...link.fields}>
        {buttonType === TallHeroBannerModuleButtonType.Primary ? (
          <SecondaryButton>{link.fields.displayText}</SecondaryButton>
        ) : (
          <TextButton
            className={classnames(styles.textButton, textColorClassName)}
          >
            {link.fields.displayText}
          </TextButton>
        )}
      </LocalizableLink>
    );
  } catch (error) {
    Logger.warn('Error rendering TallHeroBannerModuleButton', error);
    return null;
  }
};

export default TallHeroBannerModuleButton;
