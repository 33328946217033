/* tslint:disable:prefer-for-of */

import { RefObject, useEffect, useRef, useState } from 'react';

type UseCaptionsProps = {
  enableCaptionExtraction?: boolean;
  hideNativeCaptions?: boolean;
  videoRef: RefObject<HTMLVideoElement>;
};

const useCaptions = ({
  enableCaptionExtraction,
  hideNativeCaptions,
  videoRef,
}: UseCaptionsProps) => {
  const [currentCaption, setCurrentCaption] = useState<string | undefined>();
  const currentCaptionId = useRef<string | undefined>();
  const [metadataLoaded, setMetadataLoaded] = useState(false);
  const [captionsConfigured, setCaptionsConfigured] = useState(false);
  const [metadataHandlerAdded, setMetadataHandlerAdded] = useState(false);

  const onCueEnter = (e: Event) => {
    const vttCue = e.target as VTTCue | undefined;

    if (vttCue) {
      setCurrentCaption(vttCue.text);
      currentCaptionId.current = vttCue.id;
    }
  };

  const onCueExit = (e: Event) => {
    const vttCue = e.target as VTTCue | undefined;

    if (currentCaptionId.current === vttCue?.id) {
      setCurrentCaption(undefined);
      currentCaptionId.current = undefined;
    }
  };

  useEffect(() => {
    if (videoRef && enableCaptionExtraction === true) {
      const video = videoRef.current;

      const handleLoadedMetadata = () => {
        setMetadataLoaded(true);
      };

      if (!metadataHandlerAdded) {
        videoRef.current?.addEventListener(
          'loadedmetadata',
          handleLoadedMetadata
        );
        setMetadataHandlerAdded(true);
      }

      return () => {
        const tracks = video?.textTracks[0];

        const cues = tracks?.cues;

        if (cues) {
          for (let i = 0; i < cues.length; i++) {
            cues[i].removeEventListener('enter', onCueEnter);
            cues[i].removeEventListener('exit', onCueExit);
          }
        }

        video?.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [videoRef, enableCaptionExtraction, metadataHandlerAdded]);

  // workaround for safari and mobile browsers. safari will keep setting the track to show.
  if (
    hideNativeCaptions &&
    metadataLoaded &&
    videoRef.current?.textTracks?.length &&
    videoRef.current?.textTracks[0]
  ) {
    videoRef.current.textTracks[0].mode = 'hidden';
  }

  // there seems to be some delay between when the video's loadedmetadata event fires and when the cues are present in the element.
  // this is a workaround for that delay where if the cues don't get setup after the event fires then it will keep trying every render.
  if (enableCaptionExtraction && !captionsConfigured) {
    const video = videoRef.current;

    const tracks = video?.textTracks[0];

    const cues = tracks?.cues;

    if (cues && cues.length) {
      setMetadataLoaded(true);
      setCaptionsConfigured(true);

      for (let i = 0; i < cues.length; i++) {
        cues[i].addEventListener('enter', onCueEnter);
        cues[i].addEventListener('exit', onCueExit);
      }
    }
  }

  return { currentCaption };
};

export default useCaptions;
