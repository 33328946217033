import { Column, Row } from 'components/Grid';

import { LayoutHorizontalStaggerEntry } from 'lib/contentful';
import { renderContentfulEntry } from 'lib/contentful/component';
import Logger from 'lib/utils/Logger';

import styles from './ContentfulLayoutModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type HorizontalStaggerModuleProps = ContentfulModuleProps<
  LayoutHorizontalStaggerEntry
>;

const HorizontalStaggerModule = ({
  className,
  dataAttributes,
  entry,
  moduleIndex,
}: HorizontalStaggerModuleProps) => {
  try {
    const { contentModules } = entry.fields;
    const staggeredModules = contentModules.map((module, index) => {
      const columnBreakpoints =
        index % 2 === 0
          ? { md: 7, offset: { md: 2, sm: 1 }, sm: 9, xs: 12 }
          : { md: 7, offset: { md: 3, sm: 2 }, sm: 9, xs: 12 };
      return (
        <Column
          className={styles.moduleTopMargin}
          key={`column_${index}`}
          {...columnBreakpoints}
        >
          {renderContentfulEntry(module, {
            moduleIndex: `${moduleIndex}.${index}`,
          })}
        </Column>
      );
    });

    return (
      <Row className={className} {...dataAttributes}>
        {staggeredModules}
      </Row>
    );
  } catch (error) {
    Logger.warn('Error rendering HorizontalStaggerModule', error);
    return null;
  }
};

export default HorizontalStaggerModule;
