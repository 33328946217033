import { ReactElement } from 'react';

import UltimateButton from 'components/Banner/UltimateBannerModule/UltimateButton/UltimateButton';
import { Hidden, Visible } from 'components/Grid';
import VideoPlayer from 'components/VideoPlayer';
import { Logger } from 'test/integration/utils/Logger';

import { VideoBannerModuleEntry } from 'lib/contentful';

import styles from './VideoBannerModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

export type VideoBannerModuleProps = ContentfulModuleProps<
  VideoBannerModuleEntry
>;

export const VideoBannerModule = (
  props: VideoBannerModuleProps
): ReactElement | null => {
  try {
    const {
      button,
      cloudinaryPublicId,
      description,
      mobileCloudinaryPublicId,
      title,
    } = props.entry.fields;

    return (
      <div className={styles.root}>
        <div className={styles.videoContainer}>
          <Hidden xs>
            <VideoPlayer
              autoPlay
              cloudinaryPublicId={cloudinaryPublicId}
              containerClassName={styles.video}
              hasAudioTrack
              playsInline
              style={{ width: '100%' }}
              useNativeControls
            />
          </Hidden>
          <Visible xs>
            <VideoPlayer
              autoPlay
              cloudinaryPublicId={mobileCloudinaryPublicId}
              containerClassName={styles.video}
              hasAudioTrack
              playsInline
              style={{ width: '100%' }}
              useNativeControls
            />
          </Visible>
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        <UltimateButton {...button} customStyles={styles.button} />
      </div>
    );
  } catch (error) {
    Logger.error('VideoBannerModule error: ', error);
    return null;
  }
};
