import classnames from 'classnames';

import ContentfulImage from 'components/contentfulImage/ContentfulImage';
import { OPTIMIZED_QUALITY } from 'components/contentfulModules/utils';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { GridLayoutModuleItemEntry, TextColor } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

import styles from './GridLayoutItem.module.scss';

const SMALL_ITEM_WIDTH = 500;
const LARGE_ITEM_WIDTH = 900;

type GridLayoutItemProps = {
  entry: GridLayoutModuleItemEntry;
  isSmall?: boolean;
};

const GridLayoutItem = (props: GridLayoutItemProps) => {
  try {
    const { entry, isSmall } = props;
    const {
      header,
      image,
      link,
      mobileImage,
      subHeader,
      textColor,
    } = entry.fields;
    const imageUrl = getUrlFromAsset(image);
    const mobileImageUrl = mobileImage
      ? getUrlFromAsset(mobileImage)
      : imageUrl;
    const defaultGridLayoutItemImageProps = {
      limitToDPR1: true,
      quality: OPTIMIZED_QUALITY,
      width: isSmall ? SMALL_ITEM_WIDTH : LARGE_ITEM_WIDTH,
    };

    const textColorClassName =
      textColor === TextColor.Dark ? styles.blackText : styles.whiteText;

    return (
      <div
        className={classnames(styles.root, textColorClassName, {
          [styles.small]: isSmall,
        })}
      >
        <LocalizableLink {...link.fields} className={styles.linkWrapper}>
          <ContentfulImage
            alt={subHeader || header}
            className={styles.image}
            mediumSrc={{
              ...defaultGridLayoutItemImageProps,
              url: imageUrl,
            }}
            src={{
              ...defaultGridLayoutItemImageProps,
              url: mobileImageUrl,
            }}
          />
          <div className={styles.content}>
            <div className={styles.header}>{header}</div>
            <div className={styles.subHeader}>{subHeader}</div>
            <div className={styles.link}>{link.fields.displayText}</div>
          </div>
        </LocalizableLink>
      </div>
    );
  } catch (error) {
    Logger.error(`GridLayoutItem - props: ${JSON.stringify(props)}`, error);
    return null;
  }
};

export default GridLayoutItem;
