import classnames from 'classnames';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';

import PictureWall from './PictureWall';
import SummaryMetaArea from './SummaryMetaArea';
import {
  getEditorialSetName,
  getImageSrcs,
  getShoppablePhotos,
  shouldWrapModuleWithLayout,
  withDefaultLayout,
} from './utils';
import UltimateBannerModule from 'components/Banner/UltimateBannerModule/UltimateBannerModule';
import DotDivider from 'components/Dividers/DotDivider/DotDivider';
import { Column, Container, Row } from 'components/Grid';
import Image from 'components/Image/Image';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';
import ShoppableImageLightbox from 'components/ShoppableImageLightbox';
import SocialSharingLinks from 'components/SocialSharingLinks';
import ErrorPageBody from 'containers/ErrorPage/ErrorPageBody/ErrorPageBody';

import { EditorialPageModel, ShoppablePhotoEntry } from 'lib/contentful';
import { renderContentfulEntry } from 'lib/contentful/component';
import Logger from 'lib/utils/Logger';

import styles from './Editorial.module.scss';

type EditorialContainerProps = {
  editorialPage: EditorialPageModel;
  shareImageUrl: string;
  slug: string;
};

type ShoppableModalContextProps = {
  openLightboxByEntry: (entry: ShoppablePhotoEntry) => void;
};
export const ShoppableModalContext = React.createContext<
  ShoppableModalContextProps
>({
  openLightboxByEntry: () => undefined,
});

const EditorialContainer = ({
  editorialPage,
  shareImageUrl,
  slug,
}: EditorialContainerProps) => {
  /**
   * Define state here
   */

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [
    selectedShoppablePhotoIndex,
    setSelectedShoppablePhotoIndex,
  ] = useState(0);

  try {
    const {
      bannerCaption,
      bannerImage,
      hideSummaryTextAndShare = false,
      linkedEntry,
      mobileBannerImage,
      modules,
      title,
      ultimateBanner,
    } = editorialPage;

    const imageSrcs = getImageSrcs(bannerImage, mobileBannerImage);
    const editorialSetName = getEditorialSetName(editorialPage);
    const shoppablePhotos = getShoppablePhotos(editorialPage, slug);

    const openLightbox = (shoppablePhotoIndex: number) => {
      setSelectedShoppablePhotoIndex(shoppablePhotoIndex);
      setLightboxIsOpen(true);
    };

    const openLightboxByEntry = (entry: ShoppablePhotoEntry) => {
      const index = findIndex(
        shoppablePhotos,
        shoppablePhoto => shoppablePhoto.sys.id === entry.sys.id
      );
      setSelectedShoppablePhotoIndex(index);
      setLightboxIsOpen(true);
    };

    return (
      <>
        <ShoppableModalContext.Provider
          value={{
            openLightboxByEntry,
          }}
        >
          <Container>
            {/* Title area */}
            <Row>
              <Column md={10} offset={{ md: 1, xs: 0 }} xs={12}>
                {ultimateBanner && (
                  <UltimateBannerModule
                    className={styles.ultimateBanner}
                    entry={ultimateBanner}
                  />
                )}
                <div className={styles.editorialHeading}>
                  {editorialSetName && (
                    <div className={styles.editorialSetName}>
                      {editorialSetName}
                    </div>
                  )}
                  <h1 className={styles.title}>{title}</h1>
                  <div className={styles.ctaContainer}>
                    <div>
                      <button
                        className={classnames(
                          styles.ctaLink,
                          styles.buttonReset
                        )}
                        onClick={() => {
                          openLightbox(0);
                        }}
                      >
                        View Gallery
                      </button>
                    </div>
                    {linkedEntry && (
                      <>
                        <DotDivider />
                        <div>
                          <LocalizableLink
                            className={styles.ctaLink}
                            {...linkedEntry.fields}
                          >
                            Shop Lett on Verishop
                          </LocalizableLink>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Column>
            </Row>

            {/* Banner image with caption */}
            <Image className={styles.bannerImage} {...imageSrcs} />
            <Row>
              <Column offset={{ md: 1 }}>
                <div className={styles.bannerCaption}>{bannerCaption}</div>
              </Column>
            </Row>

            {/* Summary, social sharing */}
            {!hideSummaryTextAndShare && (
              <SummaryMetaArea
                className={styles.moduleTopMargin}
                editorialPage={editorialPage}
                shareImageUrl={shareImageUrl}
              />
            )}

            {/* Render all modules */}
            {modules.map((mod, index) => {
              const className = styles.moduleTopMargin;

              const contentfulComponent = renderContentfulEntry(mod, {
                className,
                moduleIndex: index,
              });

              return shouldWrapModuleWithLayout(mod)
                ? withDefaultLayout(contentfulComponent)
                : contentfulComponent;
            })}

            {/* Render bottom sharing area */}
            <Row>
              <Column xs={12}>
                <div className={styles.bottomShareContainer}>
                  <div className={styles.bottomShareTitle}>
                    Share this story
                  </div>
                  <SocialSharingLinks
                    className={styles.bottomSharingLinks}
                    shareImageUrl={shareImageUrl}
                  />
                </div>
              </Column>
            </Row>

            <Row>
              <Column md={10} offset={{ md: 1 }} xs={12}>
                <PictureWall
                  onImageClick={openLightbox}
                  shoppablePhotos={shoppablePhotos}
                />
              </Column>
            </Row>
          </Container>

          {!isEmpty(shoppablePhotos) && (
            <ShoppableImageLightbox
              isOpen={lightboxIsOpen}
              onClose={() => setLightboxIsOpen(false)}
              onNavigatePhoto={openLightbox}
              selectedPhotoIndex={selectedShoppablePhotoIndex}
              setPhotoIndex={setSelectedShoppablePhotoIndex}
              shoppablePhotos={shoppablePhotos}
            />
          )}
        </ShoppableModalContext.Provider>
      </>
    );
  } catch (error) {
    Logger.error(`Unable to render editorial '${slug}'`, error);
    return <ErrorPageBody />;
  }
};

export default EditorialContainer;
